import numeral from 'numeral';

export const formatDataForTable = (
    data,
    comparisonsFilters) => {
    let graphData = [];
    data.map((field, index) => {
        let graphItem = generateGraphInput({
            field,
            index, comparisonsFilters
        });
        graphData.push(graphItem);
        return null;
    });
    return graphData;
};

const generateGraphInput = ({ field, index, comparisonsFilters = [] }) => {
    let { filteredField, filteredValue } =
        getFilteredFiled(field, comparisonsFilters);
    return ({
        [filteredField]: filteredValue,
        key: index,
        item: field.item,
        dimension: field.dimension,
        bias: getBias(field),
        spread: getSpread(field),
        trend: field.trend,
        benchmark: getBenchMark(field.benchmark),
        impact: field.impact
    });
};

// const getItemValue= (value) => {
//     return (
//         <div 
//             className='table-item-field'>
//             {value}
//         </div>
//     );
// };

const getBenchMark = (benchmark) => {
    if (!benchmark) return undefined;
    return {
        value: getNumeralValue(benchmark.value),
        amount: Math.abs(benchmark.value),
        significant: benchmark.significant
    };
};

const getBias = (field) => [{
    Favorable: field.favScore,
    Unfavorable: field.unFavScore,
    Neutral: field.neutralScore
}];

const getSpread = (field) => ({
    value: getNumeralValue(field.spread),
    key: field.spread
});

const getFilteredFiled = (field, filtersList) => {
    let filteredValue;
    let filteredField;
    filtersList.map(filter => {
        if (field[filter.text]) {
            filteredValue = field[filter.text];
            filteredField = filter.label;
        };
    });
    return {
        filteredField: filteredField || '',
        filteredValue: filteredValue || ''
    };
};

const getNumeralValue = (value) => {
    return numeral(value).format('0');
};

export const filterDuplicateObjectsByKey = (data, key) => {
    var result = Object.values(
        data.reduce((c, e) => {
            if (!c[e[key]]) c[e[key]] = e;
            return c;
        }, {})
    );
    return result
}

export const getScoresForGraph = (data) => {
    let chartData = {
        favScore: Math.round(data.favScore),
        neutralScore: Math.round(data.neutralScore),
        unFavScore: Math.round(data.unFavScore),
        favScoreLabel: Math.round(data.favScore),
        neutralScoreLabel: Math.round(data.neutralScore),
        unFavScoreLabel: Math.round(data.unFavScore),
    };
    let total = chartData.favScore + chartData.neutralScore + chartData.unFavScore
    if (total !== 100) {
        let diff = 100 - total;
        if (chartData.favScore >= chartData.unFavScore && chartData.favScore >= chartData.neutralScore) {
            chartData.favScore += diff;
        } else if (chartData.unFavScore >= chartData.favScore && chartData.unFavScore >= chartData.neutralScore) {
            chartData.unFavScore += diff;
        } else {
            chartData.neutralScore += diff;
        }
    }
    return chartData
};