import React from 'react';
import './index.scss';
import { Tooltip } from 'antd';

export class TopicAdminBarChartLine extends React.Component {
  render() {
    const props = this.props;
    return (
      <div className='admin-progress progress-bar'>
        <Tooltip
          placement='bottom'
          title={props.data.favScore <= 6 ? `${props.data.favScoreLabel} Favorable` : "Favorable"}
        >
          <div
            className='favourable'
            style={{
              width: `${props.data.favScore}%`,
              //  minWidth: props.data.favScore === 0 ? '0%' : '35px',
              padding: props.data.favScore === 0 && '0',
            }}
          >
            {props.data.favScore > 6 && <p>{`${props.data.favScoreLabel} `}</p>}
          </div>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title={props.data.neutralScore <= 6 ? `${props.data.neutralScoreLabel} Neutral` : "Neutral"}>
          <div
            className='neutral'
            style={{
              width: `${props.data.neutralScore}%`,
              //   minWidth: props.data.neutralScore === 0 ? '0%' : '35px',
              padding: props.data.neutralScore === 0 && '0',
            }}
          >
            {props.data.neutralScore > 6 && <p>{`${props.data.neutralScoreLabel} `}</p>}
          </div>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title={props.data.unFavScore <= 6 ? `${props.data.unFavScoreLabel} Unfavorable` : "Unfavorable"}>
          <div
            className='unfavourable'
            style={{
              width: `${props.data.unFavScore}%`,
              //  minWidth: props.data.unFavScore === 0 ? '0%' : '35px',
              padding: props.data.unFavScore === 0 && '0',
            }}
          >
            {props.data.unFavScore > 6 && <p>{`${props.data.unFavScoreLabel} `}</p>}
          </div>
        </Tooltip>
      </div>
    );
  }
}
