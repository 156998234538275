import { QUESTION_TYPE } from "../../../../../config/questionTypeConstants"
import { toast } from "../../../../utils/messageUtils"

export const autoMatchSurveys = (state,payload)=>{
    if (!state.pairingTableDropDownValue) {
        return {
            ...state
        }
    }
    let updatedQuestionSets = getUpdatedData(state, payload)
   let updatedunMappedSurvey = getUpdatedUnMappedSurvey(state.trendPairingDataApi.unmappedSurvey,updatedQuestionSets.matchSurveyData)
    toast.success("Automatching Completed")
   return {
    ...state,
    trendPairingDataApi: {
        ...state.trendPairingDataApi,
        currentSurvey: updatedQuestionSets.currentSurveyData,
        unmappedSurvey: updatedunMappedSurvey,
        }
    }
}

const getUpdatedUnMappedSurvey = (unMappedSurvey, updatedData) => {
    let newData = []
    unMappedSurvey.forEach((item, index) => {
        if (updatedData.projectId === item.projectId) {
            newData.push(updatedData)
        } else {
            newData.push(item)
        }
    })
    return newData
}

const getUpdatedData = (state, payload) => {
    let data = {currentSurveyData:[...state.trendPairingDataApi.currentSurvey],matchSurveyData:[{...payload.matchSurveyData.data}]}
    let updatedData = state.trendPairingDataApi.currentSurvey
    if(payload.currentSurveyData.isFileUpload && payload.matchSurveyData.isFileUpload){
        return fileUploadAndFileUploadSurvey(data)
    }else if(!payload.currentSurveyData.isFileUpload && !payload.matchSurveyData.isFileUpload){
        return normalAndNormalSurvey(data, payload.currentSurveyData.isFromSurveyWizard)
    }else if (payload.currentSurveyData.isFileUpload && !payload.matchSurveyData.isFileUpload){
        return fileUploadNormalSurvey(data)
    }else if(!payload.currentSurveyData.isFileUpload && payload.matchSurveyData.isFileUpload){
        return normaAndFileUploadSurvey(data)
    }
    return updatedData
}

const checkIfAddMappingQuestion = (mappingQuestion,projectId)=>{
    if(mappingQuestion&&Array.isArray(mappingQuestion)){
        let addQues = true
        mappingQuestion.forEach(item=>{
            if(item.projectId===projectId){
                addQues = false
            }
        })
        return addQues
    }
    return true
}

const fileUploadNormalSurvey = (data)=>{
    let currentSurveyQuestionSet = data.currentSurveyData[0].questionSet
    let matchSurveyQuestionSet = data.matchSurveyData[0].questionSet
    let matchSurveyDetails = {projectId:data.matchSurveyData[0].projectId,projectName:data.matchSurveyData[0].projectName}
    matchSurveyQuestionSet.forEach(item=>{
        if(item.isDefault){
            let matchQuesMatrix = []
            item.questions[0].matrixQuestions.forEach(question=>{
                currentSurveyQuestionSet[0].questions.forEach(currentQues=>{
                    if(currentQues.type ==="Multiple Choice"){
                        if(currentQues.staticQuestionId === question.staticQuestionId && checkIfAddMappingQuestion(currentQues.mappingQuestions,matchSurveyDetails.projectId)){
                            let mapQues = currentQues.mappingQuestions && Array.isArray(currentQues.mappingQuestions) ? currentQues.mappingQuestions : []
                            matchQuesMatrix.push(currentQues.staticQuestionId)
                            currentQues.mappingQuestions = [...mapQues, { dimension: item.dimension, ...matchSurveyDetails, text: question.text, questionId: question.staticQuestionId }]
                        }
                    }
                })
            })
        }

    })
    return {currentSurveyData:[{...data.currentSurveyData[0],questionSet:currentSurveyQuestionSet}],matchSurveyData:[{...data.matchSurveyData[0],questionSet:matchSurveyQuestionSet}]}
}

const normaAndFileUploadSurvey=(data)=>{
    let currentSurveyQuestionSet = data.currentSurveyData[0].questionSet
    let matchSurveyQuestionSet = data.matchSurveyData[0].questionSet
    let matchSurveyDetails = { projectId: data.matchSurveyData[0].projectId, projectName: data.matchSurveyData[0].projectName }
    matchSurveyQuestionSet[0].questions.forEach(question => {
        if (question.type === "Multiple Choice") {
            currentSurveyQuestionSet.forEach((currentTopic) => {
                if (currentTopic.isDefault || currentTopic.isCustom) {
                    currentTopic.questions[0].matrixQuestions.forEach((currentQuestion) => {
                        if (currentQuestion.staticQuestionId === question.staticQuestionId && checkIfAddMappingQuestion(currentQuestion.mappingQuestions, matchSurveyDetails.projectId)) {
                            let mapQues = currentQuestion.mappingQuestions && Array.isArray(currentQuestion.mappingQuestions) ? currentQuestion.mappingQuestions : []
                            currentQuestion.mappingQuestions = [...mapQues, { dimension: question.questionDimension, ...matchSurveyDetails, text: question.question, questionId: question.staticQuestionId }]
                        }
                    })
                }

            })
        }
    })
    return { currentSurveyData: [{ ...data.currentSurveyData[0], questionSet: currentSurveyQuestionSet }], matchSurveyData: [{ ...data.matchSurveyData[0], questionSet: matchSurveyQuestionSet }] }
}

const normalAndNormalSurvey = (data, isFromSurveyWizard) => {
    let currentSurveyQuestionSet = data.currentSurveyData[0].questionSet
    let matchSurveyQuestionSet = data.matchSurveyData[0].questionSet
    let matchSurveyDetails = { projectId: data.matchSurveyData[0].projectId, projectName: data.matchSurveyData[0].projectName }
    matchSurveyQuestionSet.forEach(item => {
        if (isFromSurveyWizard ? item.type === QUESTION_TYPE.MATRIX : item.isDefault) {
            item.questions[0].matrixQuestions.forEach(question => {
                currentSurveyQuestionSet.forEach((currentTopic) => {
                    if (isFromSurveyWizard ? currentTopic.type === QUESTION_TYPE.MATRIX : currentTopic.isDefault || currentTopic.isCustom) {
                        currentTopic.questions[0].matrixQuestions.forEach((currentQuestion) => {
                            if (currentQuestion.staticQuestionId === question.staticQuestionId && checkIfAddMappingQuestion(currentQuestion.mappingQuestions, matchSurveyDetails.projectId)) {
                                let mapQues = currentQuestion.mappingQuestions && Array.isArray(currentQuestion.mappingQuestions) ? currentQuestion.mappingQuestions : []
                                currentQuestion.mappingQuestions = [...mapQues, { dimension: item.dimension, ...matchSurveyDetails, text: question.text, questionId: question.staticQuestionId }]
                            }
                        })
                    }

                })

            })
        }
    })
    return { currentSurveyData: [{ ...data.currentSurveyData[0], questionSet: currentSurveyQuestionSet }], matchSurveyData: [{ ...data.matchSurveyData[0], questionSet: matchSurveyQuestionSet }] }
}

const fileUploadAndFileUploadSurvey=(data)=>{
    let currentSurveyQuestionSet = data.currentSurveyData[0].questionSet
    let matchSurveyQuestionSet = data.matchSurveyData[0].questionSet
    let matchSurveyDetails = { projectId: data.matchSurveyData[0].projectId, projectName: data.matchSurveyData[0].projectName }
    matchSurveyQuestionSet[0].questions.forEach(question => {
        if (question.type === "Multiple Choice") {
            currentSurveyQuestionSet[0].questions.forEach((currentQuestion) => {
                if (currentQuestion.type === "Multiple Choice") {
                    if (currentQuestion.staticQuestionId === question.staticQuestionId && checkIfAddMappingQuestion(currentQuestion.mappingQuestions, matchSurveyDetails.projectId)) {
                        let mapQues = currentQuestion.mappingQuestions && Array.isArray(currentQuestion.mappingQuestions) ? currentQuestion.mappingQuestions : []
                        currentQuestion.mappingQuestions = [...mapQues, { dimension: question.questionDimension, ...matchSurveyDetails, text: question.question, questionId: question.staticQuestionId }]
                    }

                }
            })
        }
    })
    return { currentSurveyData: [{ ...data.currentSurveyData[0], questionSet: currentSurveyQuestionSet }], matchSurveyData: [{ ...data.matchSurveyData[0], questionSet: matchSurveyQuestionSet }] }
}