import React from 'react';
import { TopicBarChartLine } from '../../../../../../../Components/TopicBarChartLine';
import { TopicComments } from './TopicComments';
import { OutliersSection } from './OutliersSection';
import { TopicQuestions } from './TopicQuestions';
import { Spin } from 'antd';
import { getScoresForGraph } from '../../../../../../../utils/formatDataForTable';

export class PanelContent extends React.Component {

  checkIfFilterAppliedOrTreeDataSelected = () => {
    // return noTreeDataExist;
    return true;
    // NoFilterExist&&
  };

  checkIfOutlierDataIsEmptyOrNot = (data) => {
    if (data && data.positiveOutliers && data.negativeOutliers) {
      if (
        (!!data.positiveOutliers.length) ||
        !!data.negativeOutliers.length
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }

  };

  render() {
    const { data, dimesionItem } = this.props;
    return (
      <div className='topics-description'>
        <TopicBarChartLine data={getScoresForGraph(dimesionItem)} />
        {this.checkIfFilterAppliedOrTreeDataSelected() ? (
          <>
            <div className='topics-description__barchart'>
              <h2>All questions</h2>
              <TopicQuestions
                itemTableData={this.props.itemTableData}
                topic={this.props.topic}
              />
            </div>
            <Spin spinning={this.props.topicStoryLoading && !this.checkIfOutlierDataIsEmptyOrNot(data)} tip='fetching topic stories...'>
            <TopicComments {...this.props} />
            {this.checkIfOutlierDataIsEmptyOrNot(data) && (
              <OutliersSection
                positiveOutliers={data.positiveOutliers}
                negativeOutliers={data.negativeOutliers}
                data={data}
                showStoryboardProgress={this.props.showStoryboardProgress}
              />
            )}
          </Spin>
          </>
    ) : ''
  }


      </div>
    );
  }
}
