import { notification } from 'antd';
import { EDIT_SURVEY_RESPONSE_TABLE } from '../../../../../../config/constants';
import constants from './constants';
import { ApiBase } from "../../../../../utils/apiBase";
import { getProjectIdAndOrgId } from '../../../../../utils/apiUtils';

export const SurveyWizardEditSurveyResponse = (params = {}, callback) => {
    const apiBaseInstance = new ApiBase().instance;
    return dispatch => {
        apiBaseInstance.post(EDIT_SURVEY_RESPONSE_TABLE, { ...params, ...getProjectIdAndOrgId(params) })
            .then(res => updateUsersData(dispatch, res, callback))
            .catch(err => updateUsersDataFailed(dispatch, err, callback));
    };
};

function updateUsersData(dispatch, res, callback) {
    notification.success({ message: constants.updateUserDetailsSuccess });
    callback && callback();
}

function updateUsersDataFailed(dispatch, err, callback) {
    notification.error({ message: constants.updateUserDetailsFailed });
    callback && callback(true);
    return dispatch({ type: '' });
}