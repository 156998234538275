import React from "react";
import { compareByAlpha } from "../../../../../../../../utils/compareByAlpha";
import SVGIcons from "../../../../../../../../Components/SVGIcons";
import { QuestionBarchart } from "../Barchart";
import { checkIfAnyFilterIsApplied } from "../../../../../../../../utils/filterUtils";
import { Tooltip } from "antd";
import { getBenchmarkTooltipTitle } from '../../../../../../../../utils/benchmarkUtils';
import parse from "html-react-parser";
import { getScoresForGraph } from "../../../../../../../../utils/formatDataForTable";


export default {
  tableColumnPositiveOutLiers: (props) => [
    {
      title: "Topics",
      key: "dimension",
      dataIndex: "dimension",
      width: 150,
      sorter: (b, a) => compareByAlpha(a.dimension, b.dimension),
    },
    {
      title: "Questions",
      key: "item",
      dataIndex: "item",
      showSorterTooltip: false,
      render: (str, record) => (
        <>
          {/* <Tooltip placement="topLeft" title={str}> */}
          {parse(str)}
          {/* </Tooltip> */}
        </>
      ),
      sorter: (b, a) => compareByAlpha(a.item, b.item),
    },
    {
      title: "Score",
      key: "results",
      width: 230,
      dataIndex: ["favScore", "unFavScore", "neutralScore"],
      sorter: (b, a) => compareByAlpha(a.favScore, b.favScore),
      render: (item, record) =>
        record.validCount >= props.threshold ? (
          <QuestionBarchart setMinWidth={true} data={[getScoresForGraph(record)]} />
        ) : (
          "Insufficient data!"
        ),
    },

    ...props.showBenchMark && !props.allBenchMarkScoreEmpty ? [{
      title: <Tooltip title={getBenchmarkTooltipTitle(props.benchmarkTooltip)}>Benchmark</Tooltip>,
      dataIndex: "benchmarkScore",
      key: "benchmarkScore",
      width: 80,
      sorter: (b, a) => compareBenchMark(a, b),
      render: (item, record) => (
        <div className="table-arrows row-content">
          {record.benchmarkScore ?
            getSignedValue(record)
            : "-"}
        </div>
      ),
    },] : [],

    ...props.showBenchMark && !checkIfAnyFilterIsApplied() && !props.allTrendBenchMarkEmpty ? [{
      title: "Trend",
      dataIndex: "benchMarkTwoScore",
      key: "trend",
      width: 80,
      sorter: (b, a) => comparebenchMarkTwoScore(a, b),
      render: (item, record) => (
        <div className="table-arrows row-content">
          <span>
            {!isNaN(item) && record.favScore - record.benchMarkTwoScore > 0 ? (
              <span className="up-arrow">
                <SVGIcons type={"SVG-thick-uparrow"} />
              </span>
            ) : (
              ""
            )}
            {!isNaN(item) && record.favScore - record.benchMarkTwoScore < 0 ? (
              <span className="down-arrow">
                <SVGIcons type={"SVG-thick-downarrow"} />
              </span>
            ) : (
              ""
            )}{" "}
          </span>{" "}
          {isNaN(item) ? "-" : Math.abs(record.favScore - record.benchMarkTwoScore)}
        </div>
      ),
    },] : [],
    {
      dataIndex: "validCount",
      title: "Responses",
      key: "validCount",
      width: 50,
      sorter: (b, a) => compareByAlpha(a.validCount, b.validCount),
      render: (item, record) => <span className="table-count row-content">{item}</span>,
    },
  ],
};

const getSignedValue = (record) => {
  let value = record.favScore - record.benchmarkScore
  if (value < 0) {
    return <span className="down-arrow">
      <SVGIcons type={"SVG-thick-downarrow"} />
      {Math.abs(value)}
    </span>
  } else if (value > 0) {
    return <span className="up-arrow">
      <SVGIcons type={"SVG-thick-uparrow"} />
      {value}
    </span>
  } else {
    return <span>
      0
    </span>
  }
}

const compareBenchMark = (a, b) => {
  let valueA = a.benchmarkScore ? a.favScore - a.benchmarkScore : 0
  let valueB = b.benchmarkScore ? b.favScore - b.benchmarkScore : 0
  let result = compareByBenchmark(valueA, valueB)
  return result
}

export const compareByBenchmark = (a, b) => {
  if (a > b) return -1;
  else if (a < b) return 1;
  else return 0;
};

const comparebenchMarkTwoScore = (a, b) => {
  let valueA = a.benchMarkTwoScore ? a.favScore - a.benchMarkTwoScore : 0
  let valueB = b.benchMarkTwoScore ? b.favScore - b.benchMarkTwoScore : 0
  let result = compareByBenchmark(valueA, valueB)
  return result
}